import { FetchDefaultSubscriptionResponse, DefaultSubscription } from './types';

export const createDefaultSubscription = (
  subscription: FetchDefaultSubscriptionResponse | null,
): DefaultSubscription | null => {
  if (!subscription) return null;

  return {
    amount: `${subscription.amount} ₽`,
    period: subscription.period > 9 ? 'год' : 'мес',
  };
};
