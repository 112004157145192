import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { fetchCommunityPageMembers } from './membersStore/membersStore';
import { fetchCommunityTeam } from './teamStore/teamStore';
import { fetchChildCommunities } from './childCommunityStore/childCommunityStore';
import { fetchDefaultSubscription, fetchPayment } from './paymentStore/paymentStore';
import { fetchAttachmentLinks } from './attachmentLinksStore/attachmentLinksStore';

import { transformCommunitiesForMenu } from './utils';

import { AppDispatch, RootState } from '@store/index';
import { ChangeFieldStateParams } from '@commonTypes/type';
import { ProfileCommunity } from '@store/businessCommunity/types';
import {
  communityPageState,
  Resource,
  SetResourceLinkParams,
  CreateCommunityResourceParams,
  FetchFilterCommunityResourcePayload,
  FilterParams,
  CommunityResponse,
  CommunityPage,
} from './types';

import { initialCommunity } from './constants';

export const communityPageSlice = createSlice({
  name: 'communityPage',
  initialState: {
    isLoading: false,
    activePage: 0,
    isOpenResourceModal: false,
    isShowFilterModal: false,
    isShowNewResourceModal: false,
    isShowResourceLinkModal: false,
    resource: {
      id: 0,
      communityId: 0,
      title: '',
      description: '',
      tags: [],
      links: [],
    },
    resourceError: {
      title: '',
      description: '',
      tags: '',
      resourceLinks: '',
    },
    isShowExitModal: false,
    resources: [],
    isShowRemoveModal: false,
    removedResourceId: null,
    menuCommunities: [],
    currentCommunity: initialCommunity,
    pageData: initialCommunity.page,
    currentCommunityTags: [],
    searchValue: '',
    initialResources: [],
    chosenTags: [],
    isEditMode: false,
    isShowMemberMenu: false,
    isShowLeaveCommunityModal: false,
    isManager: false,
  } as communityPageState,
  reducers: {
    setIsLoading: (state, { payload }: { payload: boolean }) => {
      state.isLoading = payload;
    },
    setActivePage: (state, { payload }: { payload: number }) => {
      state.activePage = payload;
    },
    setIsOpenResourceModal: (state, { payload }: { payload: boolean }) => {
      state.isOpenResourceModal = payload;
    },
    setIsShowFilterModal: (state, { payload }: { payload: boolean }) => {
      state.isShowFilterModal = payload;
    },
    setIsShowNewResourceModal: (state, { payload }: { payload: boolean }) => {
      state.isShowNewResourceModal = payload;
    },
    setIsShowResourceLinkModal: (state, { payload }: { payload: boolean }) => {
      state.isShowResourceLinkModal = payload;
    },
    setResource: (state, { payload }: { payload: Resource }) => {
      state.resource = payload;
    },
    setResourceLink: (state, { payload }: { payload: SetResourceLinkParams }) => {
      const findingResource = state.resource.id === payload.resourceId;

      if (findingResource) {
        const findingResourceLink = state.resource.links.find(
          (link) => link.id === payload.resource.id,
        );

        state.resource = findingResourceLink
          ? {
              ...state.resource,
              links: state.resource.links.map((link) => {
                if (link.id === payload.resource.id) {
                  return {
                    ...link,
                    url: payload.resource.url,
                    title: payload.resource.title,
                  };
                }

                return link;
              }),
            }
          : {
              ...state.resource,
              links: [...state.resource.links, payload.resource],
            };
      }

      state.isShowResourceLinkModal = false;
      state.isShowNewResourceModal = true;
    },
    removeResourceLink: (state, { payload }: { payload: number }) => {
      const filteredResourceLinks = state.resource.links.filter((link) => link.id !== payload);

      state.resource = { ...state.resource, links: filteredResourceLinks };
    },
    setResourceError: (state, { payload }: { payload: ChangeFieldStateParams }) => {
      state.resourceError = {
        ...state.resourceError,
        [payload.field]: payload.value,
      };
    },
    setIsShowExitModal: (state, { payload }: { payload: boolean }) => {
      state.isShowExitModal = payload;
    },
    setResources: (state, { payload }: { payload: Resource[] }) => {
      state.resources = payload;
    },
    setInitialResources: (state, { payload }: { payload: Resource[] }) => {
      state.initialResources = payload;
    },
    setIsShowRemoveModal: (state, { payload }: { payload: boolean }) => {
      state.isShowRemoveModal = payload;
    },
    setRemovedResourceId: (state, { payload }: { payload: number | null }) => {
      state.removedResourceId = payload;
    },
    filterResources: (state, { payload }: { payload: Resource[] }) => {
      state.resources = payload;
      state.removedResourceId = null;
      state.isShowRemoveModal = false;
    },
    setMenuCommunities: (state, { payload }: { payload: ProfileCommunity[] }) => {
      state.menuCommunities = transformCommunitiesForMenu(payload);
    },
    setCurrentCommunity: (state, { payload }: { payload: CommunityResponse }) => {
      state.currentCommunity = payload;
    },
    setPageData: (state, { payload }: { payload: CommunityPage }) => {
      state.pageData = payload;
    },
    setCurrentCommunityTags: (state, { payload }: { payload: string[] }) => {
      state.currentCommunityTags = payload;
    },
    setSearchValue: (state, { payload }: { payload: string }) => {
      state.searchValue = payload;
    },
    setChosenTags: (state, { payload }: { payload: string[] }) => {
      state.chosenTags = payload;
    },
    setIsEditMode: (state, { payload }: { payload: boolean }) => {
      state.isEditMode = payload;
    },
    setIsShowMemberMenu: (state, { payload }: { payload: boolean }) => {
      state.isShowMemberMenu = payload;
    },
    setIsShowLeaveCommunityModal: (state, { payload }: { payload: boolean }) => {
      state.isShowLeaveCommunityModal = payload;
    },
    setIsManager: (state, { payload }: { payload: boolean }) => {
      state.isManager = payload;
    },
    resetState: (state) => {
      state.resource = {
        id: 0,
        communityId: 0,
        title: '',
        description: '',
        tags: [],
        links: [],
      };
      state.resourceError = {
        title: '',
        description: '',
        tags: '',
        resourceLinks: '',
      };
      state.removedResourceId = null;
      state.currentCommunityTags = [];
      state.searchValue = '';
      state.activePage = 0;
      state.resources = [];
      state.isEditMode = false;
      state.isOpenResourceModal = false;
      state.isShowFilterModal = false;
      state.isShowNewResourceModal = false;
      state.isShowResourceLinkModal = false;
      state.isShowExitModal = false;
      state.isShowRemoveModal = false;
      state.initialResources = [];
      state.chosenTags = [];
      state.isShowMemberMenu = false;
      state.isShowLeaveCommunityModal = false;
      state.currentCommunity = initialCommunity;
      state.pageData = initialCommunity.page;
      state.isManager = false;
    },
    resetResourceModalState: (state) => {
      state.resource = {
        id: 0,
        communityId: 0,
        title: '',
        description: '',
        tags: [],
        links: [],
      };
    },
  },
});

export const fetchCommunityPageData =
  (communityId: number | string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(communityPageSlice.actions.setIsLoading(true));
    const userManagerCommunityId = getState().auth.user?.managedCommunityId;

    if (isNaN(+communityId)) {
      const { data: communities } = await axios.get(`/communities`, {
        params: {
          name: communityId,
          page: 1,
        },
      });
      communityId = communities.data[0].id;
    }

    try {
      const { data: community } = await axios.get<CommunityResponse>(`/communities/${communityId}`);

      if (community) {
        const { page } = community;

        if (!page) return null;

        dispatch(communityPageSlice.actions.setPageData(page));

        if (userManagerCommunityId) {
          const isManager = page.communityId === userManagerCommunityId;

          dispatch(communityPageSlice.actions.setIsManager(isManager));
        }

        dispatch(communityPageSlice.actions.setCurrentCommunity(community));
        dispatch(fetchCommunityPageMembers({ communityId: community.id }));
        dispatch(fetchCommunityTeam(community.id));
        dispatch(fetchChildCommunities({ mainCommunityId: community.id }));
        dispatch(fetchDefaultSubscription(community.id));
        dispatch(fetchPayment(community.id));
        dispatch(fetchAttachmentLinks(community.id));
      }
    } catch (e) {
      console.log('Не удалось запросить сообщество');
    } finally {
      dispatch(communityPageSlice.actions.setIsLoading(false));
    }
  };

export const fetchCommunityPageResources =
  (communityId: number) => async (dispatch: AppDispatch) => {
    dispatch(communityPageSlice.actions.setIsLoading(true));

    try {
      const resources = await axios.get<Resource[]>(`/communities/materials/${communityId}`);

      if (resources.data.length) {
        dispatch(communityPageSlice.actions.setResources(resources.data));
        dispatch(communityPageSlice.actions.setInitialResources(resources.data));
      } else {
        dispatch(communityPageSlice.actions.setResources([]));
      }
    } catch (e) {
      // TODO handle error
    } finally {
      dispatch(communityPageSlice.actions.setIsLoading(false));
    }
  };

export const createCommunityResource =
  (params: CreateCommunityResourceParams) => async (dispatch: AppDispatch) => {
    dispatch(communityPageSlice.actions.setIsLoading(true));

    try {
      const response = await axios.post(
        `/communities/materials/${params.communityId}`,
        params.data,
      );

      if (response.status === 201) {
        dispatch(communityPageSlice.actions.resetResourceModalState());
        dispatch(fetchCommunityPageResources(params.communityId));
      }
    } catch (e) {
      // TODO handle error
    } finally {
      dispatch(communityPageSlice.actions.setIsLoading(false));
    }
  };

export const removeCommunityResource =
  (resourceId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(communityPageSlice.actions.setIsLoading(true));

    const resources = getState().communityPage.resources;
    const findingResource = resources.find((resource) => resource.id === resourceId);

    if (!findingResource) return null;

    const communityId = findingResource.communityId;

    if (!communityId) return null;

    try {
      const response = await axios.delete(
        `/communities/materials/${communityId}/${findingResource.id}`,
      );

      if (response.status === 200) {
        dispatch(fetchCommunityPageResources(communityId));

        dispatch(
          communityPageSlice.actions.filterResources(
            resources.filter((resource) => resource.id !== findingResource.id),
          ),
        );

        dispatch(communityPageSlice.actions.setIsShowRemoveModal(false));
        dispatch(communityPageSlice.actions.setRemovedResourceId(null));
      }
    } catch (e) {
      // TODO handle error
    } finally {
      dispatch(communityPageSlice.actions.setIsLoading(false));
    }
  };

export const fetchCommunityPageTags = (communityId: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get<string[]>(`/communities/materials/${communityId}/tags`);

    if (response.data.length) {
      dispatch(communityPageSlice.actions.setCurrentCommunityTags(response.data));
    }
  } catch (e) {
    // TODO handle error
  }
};

export const fetchFilterCommunityResources =
  ({ communityId, tags, search }: FetchFilterCommunityResourcePayload) =>
  async (dispatch: AppDispatch) => {
    try {
      const params: FilterParams = {};

      if (search) {
        params.search = search;
      }

      if (tags) {
        params.tags = tags;
      }

      const response = await axios.get(`/communities/materials/${communityId}/filter`, { params });

      if (response.data.data) {
        dispatch(communityPageSlice.actions.setResources(response.data.data));

        if (tags?.length) {
          dispatch(communityPageSlice.actions.setResources(response.data.data));
          dispatch(communityPageSlice.actions.setIsShowFilterModal(false));
          dispatch(communityPageSlice.actions.setChosenTags(tags));
        }
      }
    } catch (e) {
      // TODO handle error
    }
  };

export default communityPageSlice;
