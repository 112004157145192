import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { createDefaultSubscription } from './utils';

import { AppDispatch } from '@store/index';
import {
  PaymentState,
  FetchDefaultSubscriptionResponse,
  FetchPaymentResponse,
  DefaultSubscription,
} from './types';

export const paymentSlice = createSlice({
  name: 'paymentStore',
  initialState: {
    isFetchingPayment: false,
    approvedPayment: '',
    defaultSubscription: null,
  } as PaymentState,
  reducers: {
    setIsFetchingPayment: (state, { payload }: { payload: boolean }) => {
      state.isFetchingPayment = payload;
    },
    setApprovedPayment: (state, { payload }: { payload: string }) => {
      state.approvedPayment = payload;
    },
    setDefaultSubscription: (state, { payload }: { payload: DefaultSubscription | null }) => {
      state.defaultSubscription = payload;
    },
    resetPayment: (state) => {
      state.isFetchingPayment = false;
      state.approvedPayment = '';
      state.defaultSubscription = null;
    },
  },
});

export const fetchDefaultSubscription = (communityId: number) => async (dispatch: AppDispatch) => {
  dispatch(paymentSlice.actions.setIsFetchingPayment(true));

  try {
    const response = await axios.get<FetchDefaultSubscriptionResponse | null>(
      `/payments/subscriptions/${communityId}/default`,
    );

    dispatch(paymentSlice.actions.setDefaultSubscription(createDefaultSubscription(response.data)));
  } catch (_e) {
    console.log('Не удалось запросить информацию о подписке');
  } finally {
    dispatch(paymentSlice.actions.setIsFetchingPayment(false));
  }
};

export const fetchPayment = (communityId: number) => async (dispatch: AppDispatch) => {
  dispatch(paymentSlice.actions.setIsFetchingPayment(true));

  try {
    const response = await axios.get<FetchPaymentResponse | null>(
      `/payments/community/?communityId=${communityId}`,
    );

    if (response.data) {
      dispatch(paymentSlice.actions.setApprovedPayment(`Оплатить ${response.data.amount} ₽`));
    }
  } catch (_e) {
    console.log('Не удалось запросить информацию о подписке');
  } finally {
    dispatch(paymentSlice.actions.setIsFetchingPayment(false));
  }
};

export default paymentSlice;
