import { SubscriptionDescription } from '@features/payments/components/SubscriptionDescription/SubscriptionDescription';
import { PaymentLayout } from '@features/payments/layouts/PaymentLayout/PaymentLayout';
import { OrderType } from '@features/payments/types/order.type';
import { SubscriptionType } from '@features/payments/types/subscription.type';
import axios from '@utils/axios';
import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import styles from './PaymentStatus.module.scss';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@shared/Button';
import { PaymentButton } from '@features/payments/components/PaymentButton/PaymentButton';

export const PaymentStatus = () => {
  const orderId = location.hash.slice(1).split(':')[1];
  const [order, setOrder] = useState<OrderType | null>(null);
  const [subscription, setSubscription] = useState<SubscriptionType | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const { status } = useParams();

  const init = useCallback(async () => {
    const {
      data: { order, subscription, phone },
    } = await axios.get(`/payments/orders/${orderId}`);
    setOrder(order);
    setSubscription(subscription);
    setPhone(phone);
  }, [orderId]);

  useEffect(() => {
    init();
  }, [init]);

  let button = <Button onClick={() => navigate(`/login`)}>Войти на платформу</Button>;

  if (status === 'fail') {
    button = (
      <Link to={`/pay/${btoa(`${subscription?.community.id}/${order?.userId}`)}`}>
        <PaymentButton>Попробовать снова</PaymentButton>
      </Link>
    );
  } else if (user) {
    button = (
      <Link to={`/lead/community/${subscription?.community.id}`}>
        <Button>На страницу сообщества</Button>
      </Link>
    );
  }

  if (!order || !subscription) {
    return null;
  }

  return (
    <PaymentLayout isLoading={false}>
      <div className={styles.status}>
        {order.status === 'approved' ? 'Оплата прошла успешно' : 'Оплата не прошла'}
      </div>
      <SubscriptionDescription subscription={subscription} order={order} phone={phone!} />
      {button}
    </PaymentLayout>
  );
};
